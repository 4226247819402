<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-form>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              No. Surat Perjanjian<span class="text-danger">*</span>
            </template>
            <b-form-input
              size="lg"
              type="text"
              disabled
              v-model="dataAsal.nomorSuratPerjanjian"
              style="width: 100%"
            ></b-form-input>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              No. Surat Perintah<span class="text-danger">*</span>
            </template>
            <b-form-input
              size="lg"
              type="text"
              disabled
              v-model="dataAsal.nomorSuratPerintah"
              style="width: 100%"
            ></b-form-input>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Nama Karyawan <span class="text-danger">*</span>
            </template>
            <b-form-input
              size="lg"
              disabled
              type="text"
              v-model="dataAsal.namaKaryawan"
              style="width: 100%"
            ></b-form-input>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Divisi Karyawan <span class="text-danger">*</span>
            </template>
            <b-form-input
              size="lg"
              disabled
              type="text"
              v-model="dataAsal.namaDivisi"
              style="width: 100%"
            ></b-form-input>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Posisi Karyawan <span class="text-danger">*</span>
            </template>
            <b-form-input
              size="lg"
              disabled
              type="text"
              v-model="dataAsal.namaPosisi"
              style="width: 100%"
            ></b-form-input>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Mitra <span class="text-danger">*</span>
            </template>
            <b-form-input
              size="lg"
              disabled
              type="text"
              v-model="dataAsal.namaMitra"
              style="width: 100%"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Koordinator <span class="text-danger">*</span>
            </template>
            <b-form-input
              size="lg"
              disabled
              type="text"
              v-model="dataAsal.namaKoordinator"
              style="width: 100%"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Cabang <span class="text-danger">*</span>
            </template>
            <b-form-input
              size="lg"
              disabled
              type="text"
              v-model="dataAsal.namaCabang"
              style="width: 100%"
            ></b-form-input>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Capem <span class="text-danger">*</span>
            </template>
            <b-form-input
              size="lg"
              disabled
              type="text"
              v-model="dataAsal.namaCapem"
              style="width: 100%"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Nominal Gaji Pokok" label-cols-md="3">
            <b-form-input
              type="number"
              disabled
              v-model="dataAsal.nominalGajiKaryawan"
            ></b-form-input>
          </b-form-group>

          <!-- <b-form-group label-cols-md="3">
            <template v-slot:label>
              Jabatan Manajemen <span class="text-danger">*</span>
            </template>
            <b-form-input
              size="lg"
              disabled
              type="text"
              v-model="dataAsal.posisiManajemen"
              style="width: 100%"
            ></b-form-input>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Nama Manajemen <span class="text-danger">*</span>
            </template>
            <b-form-input
              size="lg"
              disabled
              type="text"
              v-model="dataAsal.namaPejabatManajemen"
              style="width: 100%"
            ></b-form-input>
          </b-form-group> -->

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Tanggal Mulai Kontrak <span class="text-danger">*</span>
            </template>
            <date-picker
              v-model="dataAsal.tanggalMulaiKontrak"
              style="width: 100%"
              disabled
              :placeholder="moment(dataAsal.tanggalMulaiKontrak).format('ll')"
            ></date-picker>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Durasi Kontrak (bulan) <span class="text-danger">*</span>
            </template>
            <b-form-input
              size="lg"
              type="number"
              v-model="dataAsal.masaKontrak"
              style="width: 100%"
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Tanggal Selesai Kontrak <span class="text-danger">*</span>
            </template>
            <date-picker
              disabled
              v-model="data.tanggalSelesaiKontrak"
              style="width: 100%"
              :placeholder="moment(dataAsal.tanggalSelesaiKontrak).format('ll')"
            ></date-picker>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Detail Kontrak <span class="text-danger">*</span>
            </template>
            <b-form-textarea
              disabled
              v-model="dataAsal.detailKontrak"
              style="width: 100%"
            ></b-form-textarea>
          </b-form-group>
        </b-form>
      </b-col>
      <b-col
        ><b-form>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              No. Surat Perjanjian<span class="text-danger">*</span>
            </template>
            <b-form-input
              size="lg"
              type="text"
              v-model="$v.data.nomorSuratPerpanjangan.$model"
              style="width: 100%"
            ></b-form-input>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              No. Surat Perintah<span class="text-danger">*</span>
            </template>
            <b-form-input
              size="lg"
              type="text"
              v-model="data.nomorSuratPerintah"
              style="width: 100%"
            ></b-form-input>
          </b-form-group>

          <!-- <b-form-group label-cols-md="3">
            <template v-slot:label>
              Nama Karyawan <span class="text-danger">*</span>
            </template>
            <multiselect
              v-model="$v.data.dataKaryawanId.$model"
              :options="karyawan"
              :multiple="false"
              :disabled="true"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              track-by="dataKaryawanId"
              label="namaKaryawan"
              placeholder="-- Pilih Karyawan --"
            ></multiselect>
          </b-form-group> -->

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Nama Karyawan <span class="text-danger">*</span>
            </template>
            <b-form-input
              size="lg"
              disabled
              type="text"
              v-model="data.namaKaryawan"
              style="width: 100%"
            ></b-form-input>
          </b-form-group>

          <!-- <b-form-group label-cols-md="3">
            <template v-slot:label>
              Divisi <span class="text-danger">*</span>
            </template>
            <multiselect
              v-model="$v.data.masterDivisiId.$model"
              :options="divisi"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              track-by="id"
              @input="
                getPosisi(data.masterDivisiId),
                  (data.namaDivisi = data.masterDivisiId.namaDivisi),
                  (data.masterPosisiId = '')
              "
              label="namaDivisi"
              placeholder="-- Pilih Karyawan --"
            ></multiselect>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Posisi <span class="text-danger">*</span>
            </template>
            <multiselect
              v-model="$v.data.masterPosisiId.$model"
              :options="posisi"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              track-by="id"
              @input="
                (data.namaPosisi = data.masterPosisiId.namaPosisi),
                  (data.jabatanBaru = data.masterPosisiId.namaPosisi)
              "
              label="namaPosisi"
              placeholder="-- Pilih Posisi --"
            ></multiselect>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Nama Mitra <span class="text-danger">*</span>
            </template>
            <multiselect
              v-model="$v.data.mitraId.$model"
              :state="checkIfValid('mitraId')"
              :options="dataMitra"
              @input="getKoordinator(data.mitraId), setPenempatan()"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="namaMitra"
              placeholder="-- Pilih --"
            ></multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Nama Koordinator <span class="text-danger">*</span>
            </template>
            <multiselect
              v-model="$v.data.koordinatorId.$model"
              :state="checkIfValid('koordinatorId')"
              :options="koordinator"
              @input="getCabang(data.koordinatorId), setPenempatan()"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="namaKoordinator"
              placeholder="-- Pilih --"
            ></multiselect>
          </b-form-group>
          <b-form-group label="Nama Cabang" label-cols-md="3">
            <multiselect
              v-model="data.cabangId"
              :options="cabang"
              @input="getCapem(data.cabangId), setPenempatan()"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="namaCabang"
              placeholder="-- Pilih --"
            ></multiselect>
          </b-form-group>
          <b-form-group label="Nama Capem" label-cols-md="3">
            <multiselect
              v-model="data.capemId"
              :options="capem"
              @input="setPenempatan()"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="namaCapem"
              placeholder="-- Pilih --"
            ></multiselect>
          </b-form-group> -->

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Divisi Karyawan <span class="text-danger">*</span>
            </template>
            <b-form-input
              size="lg"
              disabled
              type="text"
              v-model="data.namaDivisi"
              style="width: 100%"
            ></b-form-input>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Posisi Karyawan <span class="text-danger">*</span>
            </template>
            <b-form-input
              size="lg"
              disabled
              type="text"
              v-model="data.namaPosisi"
              style="width: 100%"
            ></b-form-input>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Mitra <span class="text-danger">*</span>
            </template>
            <b-form-input
              size="lg"
              disabled
              type="text"
              v-model="data.namaMitra"
              style="width: 100%"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Koordinator <span class="text-danger">*</span>
            </template>
            <b-form-input
              size="lg"
              disabled
              type="text"
              v-model="data.namaKoordinator"
              style="width: 100%"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Cabang <span class="text-danger">*</span>
            </template>
            <b-form-input
              size="lg"
              disabled
              type="text"
              v-model="data.namaCabang"
              style="width: 100%"
            ></b-form-input>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Capem <span class="text-danger">*</span>
            </template>
            <b-form-input
              size="lg"
              disabled
              type="text"
              v-model="dataAsal.namaCapem"
              style="width: 100%"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Gaji Pokok" label-cols-md="3">
            <b-form-select
              :options="gapok"
              v-model="data.masterGajiId"
              @input="setNominal(data.masterGajiId)"
            ></b-form-select>
          </b-form-group>
          <b-form-group label="Nominal Gaji Pokok" label-cols-md="3">
            <b-form-input
              type="number"
              :disabled="
                data.masterGajiId != 'fc39dbe3-4bfa-4400-8618-0eddf4a0fc65'
              "
              :options="gapok"
              v-model="data.nominalGajiPerjanjian"
            ></b-form-input>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Jabatan Manajemen <span class="text-danger">*</span>
            </template>
            <multiselect
              v-model="$v.data.posisiManajemenId.$model"
              :options="manajemen"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              track-by="id"
              @input="
                (data.namaPejabatManajemen =
                  data.posisiManajemenId.namaManajemen),
                  (data.posisiManajemen =
                    data.posisiManajemenId.jabatanManajemen)
              "
              label="jabatanManajemen"
              placeholder="-- Pilih Posisi --"
            ></multiselect>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Nama Manajemen <span class="text-danger">*</span>
            </template>
            <b-form-input
              size="lg"
              type="text"
              v-model="data.namaPejabatManajemen"
              style="width: 100%"
            ></b-form-input>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Tanggal Mulai Kontrak <span class="text-danger">*</span>
            </template>
            <date-picker
              v-model="$v.data.tanggalMulaiKontrak.$model"
              style="width: 100%"
              :placeholder="moment(data.tanggalMulaiKontrak).format('ll')"
              @input="setExpire()"
            ></date-picker>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Durasi Kontrak (bulan) <span class="text-danger">*</span>
            </template>
            <b-form-input
              size="lg"
              type="number"
              v-model="$v.data.masaKontrak.$model"
              style="width: 100%"
              @input="setExpire()"
            ></b-form-input>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Tanggal Selesai Kontrak <span class="text-danger">*</span>
            </template>
            <date-picker
              v-model="data.tanggalSelesaiKontrak"
              style="width: 100%"
              :placeholder="place"
            ></date-picker>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Penempatan <span class="text-danger">*</span>
            </template>
            <b-form-input
              v-model="data.penempatan"
              style="width: 100%"
            ></b-form-input>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Detail Kontrak <span class="text-danger">*</span>
            </template>
            <b-form-textarea
              v-model="data.detailKontrak"
              style="width: 100%"
            ></b-form-textarea>
          </b-form-group> </b-form
      ></b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex justify-content-center">
        <b-button
          :disabled="!isValid || busy"
          variant="success"
          @click="simpan()"
          >{{ button }}</b-button
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import "moment/locale/id";
export default {
  components: { Multiselect, DatePicker },
  props: ["karyawan", "dataAsal", "mutate", "dataMitra", "gapok"],
  name: "modalInput",
  data() {
    return {
      moment,
      data: {},
      divisi: [],
      manajemen: [],
      posisi: [],
      koordinator: [],
      cabang: [],
      capem: [],
      place: "",
      busy: false,
      button: "Konfirmasi Perpanjang Kontrak",
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.data, null, 4);
    },
    isValid() {
      // console.log(!this.$v.data.$invalid, this.data, "valid");
      return !this.$v.data.$invalid;
    },
    isDirty() {
      return this.$v.data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    data: {
      dataKaryawanId: {
        required,
      },
      tanggalMulaiKontrak: {
        required,
      },
      masaKontrak: {
        required,
      },
      nomorSuratPerpanjangan: {
        required,
      },
      masterDivisiId: {
        required,
      },
      masterPosisiId: {
        required,
      },
      mitraId: {
        required,
      },
      posisiManajemenId: {
        required,
      },
    },
  },
  mounted() {
    this.getDivisi();
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.data[fieldName];
      console.log(field);
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    itikiwir2(x) {
      let vm = this;
      vm.data.statusKerjaKaryawan = x.statusKerjaKaryawan;
    },
    setNominal(x) {
      // console.log(x);
      this.gapok.forEach((item) => {
        if (item.id == x) {
          this.data.nominalGajiPerjanjian = item.nominalGaji;
          this.data.namaGaji = item.namaGaji;
        }
      });
    },
    setPenempatan() {
      let vm = this;
      if (vm.data.capemId) {
        vm.data.penempatan = vm.data.capemId.namaCapem;
      } else {
        if (vm.data.cabangId) {
          vm.data.penempatan = vm.data.cabangId.namaCabang;
        } else {
          if (vm.data.koordinatorId) {
            vm.data.penempatan = vm.data.koordinatorId.namaKoordinator;
          } else {
            vm.data.penempatan = vm.data.mitraId.namaMitra;
          }
        }
      }
    },
    async getMitra() {
      let listMitra = await axios.get(ipBackend + "mitra/list");
      this.dataMitra = listMitra.data.data;
    },
    async getKoordinator(x) {
      if (x) {
        let koordinator = await axios.get(
          ipBackend + "koordinator/listKoordinatorByMitraId/" + x.id
        );
        this.koordinator = koordinator.data.data;
        this.data.koordinatorId = "";
        this.data.cabangId = "";
        this.data.capemId = "";
      } else if (!x) {
        this.data.koordinatorId = "";
        this.data.cabangId = "";
        this.data.capemId = "";
      }
    },
    async getCabang(x) {
      if (x) {
        let cabang = await axios.get(
          ipBackend + "cabang/listCabangByKoordinatorId/" + x.id
        );
        this.cabang = cabang.data.data;
        this.data.cabangId = "";
        this.data.capemId = "";
      } else if (!x) {
        this.data.cabangId = "";
        this.data.capemId = "";
      }
    },
    async getCapem(x) {
      if (x) {
        let capem = await axios.get(
          ipBackend + "capem/listCapemByCabangId/" + x.id
        );
        this.capem = capem.data.data;
        this.data.capemId = "";
      } else if (!x) {
        this.data.capemId = "";
      }
    },
    async getDivisi() {
      let vm = this;
      let divisi = await axios.get(ipBackend + "masterDivisi/list");
      vm.divisi = divisi.data.data;

      let manajemen = await axios.get(ipBackend + "masterManajemen/list");
      vm.manajemen = manajemen.data.data;

      // console.log(manajemen.data.data, "iki");
    },
    async getPosisi(x) {
      let vm = this;
      let posisi = await axios.get(
        ipBackend + "masterPosisi/listPosisiByMasterDivisiId/" + x.id
      );
      // console.log(posisi.data.data);

      vm.posisi = posisi.data.data;
    },
    setKaryawan() {
      let vm = this;
      console.log(this.dataAsal, "asal");
      vm.data = {
        tanggalDibuatSurat: moment(),
        nomorSurat: "",
        nomorSuratPerjanjian: vm.dataAsal.nomorSuratPerjanjian,
        dataKaryawanId: vm.dataAsal.dataKaryawanId,
        namaKaryawan: vm.dataAsal.namaKaryawan,
        tanggalMulaiKontrak: moment(vm.dataAsal.tanggalSelesaiKontrak).add(
          1,
          "days"
        ),
        tanggalSelesaiKontrak: "",
        masaKontrak: "",
        statusPerjanjian: 0,
        statusKerjaKaryawan: 1,
        detailKontrak: "",
        nomorSuratPerpanjangan: "",
        masterDivisiId: vm.dataAsal.masterDivisiId,
        namaDivisi: vm.dataAsal.namaDivisi,
        masterPosisiId: vm.dataAsal.masterPosisiId,
        namaPosisi: vm.dataAsal.namaPosisi,
        jabatanAwal: vm.dataAsal.namaPosisi,
        jabatanBaru: "",
        statusPerjanjianAktif: 4,
        jenisMutasi: "",
        posisiManajemenId: "",
        mitraId: vm.dataAsal.mitraId,
        namaMitra: vm.dataAsal.namaMitra,
        cabangId: vm.dataAsal.cabangId,
        namaCabang: vm.dataAsal.namaCabang,
        koordinatorId: vm.dataAsal.koordinatorId,
        namaKoordinator: vm.dataAsal.namaKoordinator,
        capemId: vm.dataAsal.capemId,
        namaCapem: vm.dataAsal.namaCapem,
        penempatan: vm.dataAsal.penempatan,
        masterGajiId: "",
        nominalGajiPerjanjian: "",
      };
    },
    setExpire() {
      let vm = this;
      vm.data.tanggalSelesaiKontrak = moment(vm.data.tanggalMulaiKontrak)
        .add(vm.data.masaKontrak, "month")
        .subtract(1, "days");
      vm.place = moment(vm.data.tanggalMulaiKontrak)
        .add(vm.data.masaKontrak, "month")
        .subtract(1, "days")
        .format("YYYY-MM-DD");
    },
    simpan() {
      let vm = this;
      vm.button = "Mohon Tunggu";
      vm.busy = true;
      vm.data.tanggalPerpanjangKontrak = moment();
      vm.data.tanggalMutasi = vm.data.tanggalMulaiKontrak;
      vm.data.jenisMutasi = "Perpanjangan";
      // console.log(vm.data);
      axios
        .post(ipBackend + "suratPerjanjianKerja/perpanjangPerjanjian", vm.data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Konfirmasi Perpanjangan";
            vm.reset();
            vm.$v.$reset();
            vm.busy = false;
            vm.$emit("alert", {
              variant: "success",
              msg: "BERHASIL MEMPERPANJANG DURASI KONTRAK KARYAWAN",
              showing: true,
            });
            this.$bvModal.hide("modal-input");
          } else {
            vm.button = "Konfirmasi Mutasi";
            vm.busy = false;
            vm.$emit("alert", {
              variant: "danger",
              msg: res.data.message.toUpperCase(),
              showing: true,
            });
          }
        })
        .catch((err) => {
          vm.button = "Konfirmasi Mutasi";
          vm.busy = false;
          vm.$emit("alert", {
            variant: "danger",
            msg: err.message,
            showing: true,
          });
        });
    },
    reset() {
      this.data = {
        tanggalDibuatSurat: "",
        nomorSurat: "",
        nomorSuratAwal: "",
        nomorSuratBaru: "",
        dataKaryawanId: "",
        tanggalMulaiKontrak: "",
        tanggalSelesaiKontrak: "",
        masaKontrak: "",
        statusPerjanjian: 0,
        statusKerjaKaryawan: 1,
        detailKontrak: "",
        masterDivisiId: "",
        namaDivisi: "",
        masterPosisiId: "",
        namaPosisi: "",
        posisiManajemen: "",
        namaPejabatManajemen: "",
        jabatanAwal: "",
        jabatanBaru: "",
        statusPerjanjianAktif: 5,
        jenisMutasi: "",
        posisiManajemenId: "",
      };
    },
  },
  watch: {
    mutate: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.setKaryawan();
      }
    },
  },
};
</script>
