<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col>
          <b-card>
            <template #header>
              <b-row>
                <b-col md="6"
                  ><b-form>
                    <b-form-group label-cols-md="3">
                      <template v-slot:label>
                        Mutasi <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        type="text"
                        :options="jenis"
                        v-model="jenisMutasi"
                        @input="getKaryawan()"
                        style="width: 100%"
                        placeholder="Pilih Jenis Mutasi"
                      ></b-form-select>
                    </b-form-group>
                  </b-form>
                  <b-form-group label-cols-md="3">
                    <template v-slot:label>
                      Karyawan <span class="text-danger">*</span>
                    </template>
                    <multiselect
                      v-model="karyawanId"
                      :options="karyawan"
                      @input="itikiwir(karyawanId)"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      label="dataKaryawan"
                      placeholder="-- Pilih --"
                    ></multiselect>
                  </b-form-group>
                </b-col>
                <b-col md="6"
                  ><b-form-group label-cols-md="3">
                    <template v-slot:label> No. Surat Perjanjian </template>
                    <b-form-input
                      type="text"
                      v-model="noSuratPerjanjian"
                      disabled
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label-cols-md="3">
                    <template v-slot:label> No. Surat Perintah </template>
                    <b-form-input
                      type="text"
                      v-model="noSuratPerintah"
                      disabled
                    ></b-form-input> </b-form-group
                ></b-col>
                <b-col align="right">
                  <b-button
                    variant="secondary"
                    @click="
                      (jenisMutasi = ''),
                        (karyawanId = ''),
                        (noSuratPerintah = ''),
                        (noSuratPerjanjian = ''),
                        (dataAsal = '')
                    "
                    >Reset</b-button
                  >
                </b-col>
              </b-row>
            </template>
            <div v-if="jenisMutasi == 'Jabatan'">
              <mutasiJabatan
                :dataAsal="dataAsal"
                :karyawan="karyawan"
                :mutate="mutate"
                :dataMitra="dataMitra"
                :gapok="gapok"
                @alert="
                  (jenisMutasi = ''),
                    (karyawanId = ''),
                    (noSuratPerintah = ''),
                    (noSuratPerjanjian = ''),
                    alert($event)
                "
              />
            </div>
            <div v-if="jenisMutasi == 'Penempatan'">
              <mutasiLokasi
                :dataAsal="dataAsal"
                :karyawan="karyawan"
                :mutate="mutate"
                :dataMitra="dataMitra"
                @alert="
                  (jenisMutasi = ''),
                    (karyawanId = ''),
                    (noSuratPerintah = ''),
                    (noSuratPerjanjian = ''),
                    alert($event)
                "
              />
            </div>
            <div v-if="jenisMutasi == 'Perpanjang'">
              <perpanjangPerjanjian
                :dataAsal="dataAsal"
                :karyawan="karyawan"
                :mutate="mutate"
                :dataMitra="dataMitra"
                :gapok="gapok"
                @alert="
                  (jenisMutasi = ''),
                    (karyawanId = ''),
                    (noSuratPerintah = ''),
                    (noSuratPerjanjian = ''),
                    alert($event)
                "
              />
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card>
            <b-alert dismissible fade :show="showing" :variant="variant">{{
              msg
            }}</b-alert>
            <b-tabs content-class="mt-3" align="center" fill>
              <b-tab title="Mutasi" active>
                <tabMutasi
                  :mutasi="mutasi"
                  :news="news"
                  :dataMitra="dataMitra"
                />
              </b-tab>
              <b-tab title="Perpanjangan">
                <tabPerpanjang
                  :renew="renew"
                  :news="news"
                  :dataMitra="dataMitra"
                />
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend.js";
// import { validationMixin } from "vuelidate";
// import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
// import DatePicker from "vue2-datepicker";
// import "vue2-datepicker/index.css";
import moment from "moment";
import "moment/locale/id";
import mutasiJabatan from "./mutasiJabatan.vue";
import mutasiLokasi from "./mutasiLokasi.vue";
import perpanjangPerjanjian from "./perpanjangPerjanjian.vue";
import tabMutasi from "./tabMutasi.vue";
import tabPerpanjang from "./tabPerpanjang.vue";

export default {
  components: {
    Multiselect,
    // DatePicker,
    mutasiJabatan,
    mutasiLokasi,
    perpanjangPerjanjian,
    tabMutasi,
    tabPerpanjang,
  },
  name: "asuransi",
  data() {
    return {
      moment,
      showing: false,
      variant: "success",
      mutate: false,
      busy: false,
      msg: "",
      jenis: [
        { value: "", text: "Pilih Jenis Mutasi" },
        { value: "Jabatan", text: "Jabatan" },
        { value: "Penempatan", text: "Penempatan" },
        { value: "Perpanjang", text: "Perpanjang Kontrak" },
      ],
      data: "",
      jenisMutasi: "",
      news: false,
      dataAsal: {},
      karyawan: [],
      dataMitra: [],
      karyawanId: "",
      koordinator1: [],
      koordinator: [],
      cabang: [],
      capem: [],
      mutasi: [],
      renew: [],
      gapok: [],
      noSuratPerjanjian: "",
      noSuratPerintah: "",
      items: [
        {
          no: "-",
          nmAsuransi: "-",
        },
      ],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "namaKaryawan",
          label: "Nama Karyawan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "jenisMutasi",
          label: "Mutasi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "tanggalMutasi",
          label: "Tanggal Mutasi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "penempatanAwal",
          label: "Lokasi Lama",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "penempatanBaru",
          label: "Lokasi Baru",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "jabatanAwal",
          label: "Posisi Lama",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "jabatanBaru",
          label: "Posisi Baru",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    // Set the initial number of items
    this.getDatas();
    this.getMitra();
    this.getGaji();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getMitra() {
      let listMitra = await axios.get(ipBackend + "mitra/list");
      this.dataMitra = listMitra.data.data;
    },
    async getGaji() {
      let gaji = await axios.get(ipBackend + "masterGaji/list");
      this.gapok = gaji.data.data.map((item) => {
        let x = item.nominalGaji
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        return {
          ...item,
          value: item.id,
          text: item.namaGaji + " - " + "Rp. " + x,
        };
      });
    },
    async getKaryawan() {
      let vm = this;
      if (vm.jenisMutasi == "Jabatan" || vm.jenisMutasi == "Perpanjang") {
        let listKaryawan = await axios.get(
          ipBackend + "suratPerjanjianKerja/listPerjanjianAktif"
        );

        let penampung = listKaryawan.data.data;
        vm.karyawan = penampung.map((item) => {
          item.dataKaryawan =
            item.namaKaryawan +
            " - " +
            item.namaPosisi +
            " - " +
            item.penempatan;
          return item;
        });
        // console.log(vm.karyawan, "iki");
      } else if (vm.jenisMutasi == "Penempatan") {
        let listKaryawan = await axios.get(
          ipBackend + "poolKontrak/listPoolKontrakAktif"
        );

        let penampung = listKaryawan.data.data;
        vm.karyawan = penampung.map((item) => {
          if(item.namaKaryawan.includes('Aqtiar')){
            console.log(item)
          }
          item.dataKaryawan =
            item.namaKaryawan +
            " - " +
            item.namaDivisi +
            " - " +
            item.namaPosisi;
          return item;
        });
      }
    },
    async getDatas() {
      let vm = this;
      vm.tableBusy = true;
      let listMutasi = await axios.get(ipBackend + "mutasi/list");
      // console.log(listMutasi.data.data);
      vm.mutasi = listMutasi.data.data;
      for (let i = 0; i < vm.mutasi.length; i++) {
        let x = vm.mutasi[i];
        x.no = i + 1;
      }

      let listRenew = await axios.get(
        ipBackend + "suratPerjanjianKerja/listPerpanjangKontrak"
      );
      // console.log(listRenew.data.data, "renew");
      vm.renew = listRenew.data.data;
      for (let i = 0; i < vm.renew.length; i++) {
        let x = vm.renew[i];
        x.no = i + 1;
      }

      let listMitra = await axios.get(ipBackend + "mitra/list");
      vm.dataMitra = listMitra.data.data;

      let listKaryawan = await axios.get(ipBackend + "dataKaryawan/listKaryawan");

      let penampung = listKaryawan.data.data;
      vm.karyawan = penampung.map((item) => {
        item.dataKaryawan =
          item.namaKaryawan + " - " + item.namaDivisi + " - " + item.namaPosisi;
        return item;
      });

      vm.tableBusy = false;
    },
    alert(event) {
      let vm = this;
      // console.log(event);
      vm.showing = event.showing;
      vm.variant = event.variant;
      vm.msg = event.msg;
      vm.getDatas();
      vm.news = !vm.news;
      setTimeout(() => {
        vm.showing = false;
      }, 4000);
    },
    checkIfValid(fieldName) {
      const field = this.$v.data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    async itikiwir(x) {
      let vm = this;
      vm.noSuratPerjanjian = x.nomorSuratPerjanjian;
      vm.noSuratPerintah = x.nomorSuratPerintah;
      vm.dataAsal = x;
      console.log(x, "xx");
      vm.mutate = !vm.mutate;
    },
    async getKoordinator(x) {
      let koordinator = await axios.get(
        ipBackend + "koordinator/listKoordinatorByMitraId/" + x.id
      );
      //   console.log(koordinator.data.data, "ini koordinator");
      this.koordinator = koordinator.data.data;
    },
    async getCabang(x) {
      let cabang = await axios.get(
        ipBackend + "cabang/listCabangByKoordinatorId/" + x.id
      );
      //   console.log(cabang.data.data, "ini cabang");
      this.cabang = cabang.data.data;
    },
    async getCapem(x) {
      let capem = await axios.get(
        ipBackend + "capem/listCapemByCabangId/" + x.id
      );
      //   console.log(cabang.data.data, "ini cabang");
      this.capem = capem.data.data;
    },
  },
};
</script>
