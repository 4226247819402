<template>
  <b-container fluid>
    <b-row>
      <b-col
        ><b-form>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              No. Surat Perintah <span class="text-danger">*</span>
            </template>
            <b-form-input
              size="lg"
              type="text"
              v-model="dataAsal.nomorSuratPerintah"
              style="width: 100%"
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Nama Karyawan <span class="text-danger">*</span>
            </template>
            <b-form-input
              size="lg"
              type="text"
              v-model="dataAsal.namaKaryawan"
              style="width: 100%"
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Nama Mitra <span class="text-danger">*</span>
            </template>
            <b-form-input
              size="lg"
              type="text"
              v-model="dataAsal.namaMitra"
              style="width: 100%"
              disabled
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Nama Koordinator <span class="text-danger">*</span>
            </template>
            <b-form-input
              size="lg"
              type="text"
              v-model="dataAsal.namaKoordinator"
              style="width: 100%"
              disabled
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Nama Cabang" label-cols-md="3">
            <b-form-input
              size="lg"
              type="text"
              v-model="dataAsal.namaCabang"
              style="width: 100%"
              disabled
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Nama Capem" label-cols-md="3">
            <b-form-input
              size="lg"
              type="text"
              v-model="dataAsal.namaCapem"
              style="width: 100%"
              disabled
            ></b-form-input>
          </b-form-group>
          <!-- <b-form-group label="Posisi Manajemen" label-cols-md="3">
            <b-form-input
              size="lg"
              type="text"
              v-model="dataAsal.posisiManajemen"
              style="width: 100%"
              disabled
            ></b-form-input>
          </b-form-group>
           <b-form-group label="Nama Manajemen" label-cols-md="3">
            <b-form-input
              size="lg"
              type="text"
              v-model="dataAsal.namaPejabatManajemen"
              style="width: 100%"
              disabled
            ></b-form-input>
          </b-form-group> -->
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Tanggal Mulai Kontrak <span class="text-danger">*</span>
            </template>
            <date-picker
              v-model="dataAsal.tanggalKontrak"
              style="width: 100%"
              :placeholder="moment(dataAsal.tanggalKontrak).format('LL')"
              disabled
            ></date-picker>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Durasi Kontrak (bulan) <span class="text-danger">*</span>
            </template>
            <b-form-input
              size="lg"
              type="number"
              v-model="dataAsal.masaBerlakuKontrak"
              style="width: 100%"
              disabled
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Penempatan <span class="text-danger">*</span>
            </template>
            <b-form-input
              size="lg"
              type="text"
              v-model="dataAsal.penempatan"
              style="width: 100%"
            ></b-form-input> </b-form-group></b-form
      ></b-col>

      <b-col>
        <b-form>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              No. Surat Perintah <span class="text-danger">*</span>
            </template>
            <b-form-input
              size="lg"
              type="text"
              v-model="$v.data.nomorSurat.$model"
              @input="data.nomorSuratBaru = data.nomorSurat"
              style="width: 100%"
            ></b-form-input>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Data Karyawan <span class="text-danger">*</span>
            </template>
            <multiselect
              v-model="$v.data.dataKaryawanId.$model"
              :state="checkIfValid('dataKaryawanId')"
              :disabled="true"
              :options="karyawan"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              @input="itikiwir(data.dataKaryawanId), setPenempatan()"
              track-by="dataKaryawanId"
              label="dataKaryawan"
              placeholder="-- Pilih Karyawan --"
            ></multiselect>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Nama Mitra <span class="text-danger">*</span>
            </template>
            <multiselect
              v-model="$v.data.mitraId.$model"
              :state="checkIfValid('mitraId')"
              :options="dataMitra"
              @input="getKoordinator(data.mitraId), setPenempatan()"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="namaMitra"
              placeholder="-- Pilih --"
            ></multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Nama Koordinator <span class="text-danger">*</span>
            </template>
            <multiselect
              v-model="$v.data.koordinatorId.$model"
              :state="checkIfValid('koordinatorId')"
              :options="koordinator"
              @input="getCabang(data.koordinatorId), setPenempatan()"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="namaKoordinator"
              placeholder="-- Pilih --"
            ></multiselect>
          </b-form-group>
          <b-form-group label="Nama Cabang" label-cols-md="3">
            <multiselect
              v-model="data.cabangId"
              :options="cabang"
              @input="getCapem(data.cabangId), setPenempatan()"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="namaCabang"
              placeholder="-- Pilih --"
            ></multiselect>
          </b-form-group>
          <b-form-group label="Nama Capem" label-cols-md="3">
            <multiselect
              v-model="data.capemId"
              :options="capem"
              @input="setPenempatan()"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="namaCapem"
              placeholder="-- Pilih --"
            ></multiselect>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Jabatan Manajemen <span class="text-danger">*</span>
            </template>
            <multiselect
              v-model="$v.data.posisiManajemenId.$model"
              :options="manajemen"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              track-by="id"
              @input="
                (data.namaPejabatManajemen =
                  data.posisiManajemenId.namaManajemen),
                  (data.posisiManajemen =
                    data.posisiManajemenId.jabatanManajemen)
              "
              label="jabatanManajemen"
              placeholder="-- Pilih Posisi --"
            ></multiselect>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Nama Manajemen <span class="text-danger">*</span>
            </template>
            <b-form-input
              size="lg"
              type="text"
              v-model="data.namaPejabatManajemen"
              style="width: 100%"
            ></b-form-input>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Tanggal Mulai Kontrak <span class="text-danger">*</span>
            </template>
            <date-picker
              v-model="$v.data.tanggalKontrak.$model"
              :state="checkIfValid('tanggalKontrak')"
              style="width: 100%"
              @input="setExpire()"
            ></date-picker>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Durasi Kontrak (bulan) <span class="text-danger">*</span>
            </template>
            <b-form-input
              size="lg"
              type="number"
              v-model="$v.data.masaBerlakuKontrak.$model"
              :state="checkIfValid('masaBerlakuKontrak')"
              style="width: 100%"
              @input="setExpire()"
            ></b-form-input>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Selesai Kontrak <span class="text-danger">*</span>
            </template>
            <date-picker
              v-model="data.jatuhTempoKontrak"
              style="width: 100%"
              :placeholder="place"
            ></date-picker>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Penempatan <span class="text-danger">*</span>
            </template>
            <b-form-input
              size="lg"
              type="text"
              v-model="data.penempatan"
              style="width: 100%"
            ></b-form-input>
          </b-form-group>
        </b-form>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <center>
          <b-button
            variant="success"
            :disabled="!isValid"
            @click="$bvModal.show('modal-konfirmasi')"
          >
            {{ button }}
          </b-button>
        </center>
      </b-col>
    </b-row>
    <div>
      <b-modal
        id="modal-konfirmasi"
        size="lg"
        centered
        scrollable
        title="Konfirmasi Mutasi Penempatan"
        header-bg-variant="info"
        header-text-variant="light"
      >
        <p>
          Apakah anda yakin akan
          <span class="text-danger">Melakukan </span> Mutasi Pemenpatan a.n
          <span class="text-danger">{{ dataAsal.namaKaryawan }}</span> dari
          <span class="text-danger">{{ dataAsal.penempatan }}</span>

          ke
          <span class="text-danger">{{ data.penempatan }}</span>
          ?
        </p>

        <template #modal-footer>
          <b-button
            variant="secondary"
            @click="$bvModal.hide('modal-konfirmasi')"
          >
            Batal
          </b-button>
          <b-button variant="primary" @click="simpan()" :disabled="busy">
            Yakin
          </b-button>
        </template></b-modal
      >
    </div>
  </b-container>
</template>
<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend.js";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import "moment/locale/id";
export default {
  components: { Multiselect, DatePicker },
  props: ["dataAsal", "karyawan", "mutate"],
  data() {
    return {
      moment,
      data: {
        jenisMutasi: "",
        nomorSuratAwal: "",
        nomorSuratBaru: "",
        penempatan: "",
        penempatanAwal: "",
        penempatanBaru: "",
        tanggalMutasi: "",
        nomorSurat: "",
        dataKaryawanId: "",
        tanggalKontrak: "",
        suratPerjanjianKerjaId: "",
        mitraId: "",
        cabangId: "",
        koordinatorId: "",
        capemId: "",
        masaBerlakuKontrak: "",
        namaPejatanManajemen: "",
        posisiManajemen: "",
        posisiManajemenId: "",
        jatuhTempoKontrak: "",
      },
      place: null,
      koordinator: [],
      dataMitra: [],
      cabang: [],
      capem: [],
      manajemen: [],
      button: "Konfirmasi Mutasi",
      busy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    formString() {
      return JSON.stringify(this.data, null, 4);
    },
    isValid() {
      return !this.$v.data.$invalid;
    },
    isDirty() {
      return this.$v.data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    data: {
      dataKaryawanId: {
        required,
      },
      tanggalKontrak: {
        required,
      },
      masaBerlakuKontrak: {
        required,
      },
      mitraId: {
        required,
      },
      koordinatorId: {
        required,
      },
      nomorSurat: {
        required,
      },
      posisiManajemenId: {
        required,
      },
    },
  },
  mounted() {
    this.getMitra();
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    setKaryawan() {
      for (let i = 0; i < this.karyawan.length; i++) {
        if (this.karyawan[i].dataKaryawanId == this.dataAsal.dataKaryawanId) {
          console.log(
            this.karyawan[i].dataKaryawanId,
            this.dataAsal.dataKaryawanId
          );
          this.data.dataKaryawanId = this.karyawan[i];
        }
      }
    },
    setPenempatan() {
      let vm = this;
      if (vm.data.capemId) {
        vm.data.penempatan = vm.data.capemId.namaCapem;
      } else {
        if (vm.data.cabangId) {
          vm.data.penempatan = vm.data.cabangId.namaCabang;
        } else {
          if (vm.data.koordinatorId) {
            vm.data.penempatan = vm.data.koordinatorId.namaKoordinator;
          } else {
            vm.data.penempatan = `${vm.data.mitraId.namaMitra}`;
          }
        }
      }
    },
    setExpire() {
      let vm = this;
      vm.data.jatuhTempoKontrak = moment(vm.data.tanggalKontrak)
        .add(vm.data.masaBerlakuKontrak, "month")
        .subtract(1, "days")
        .format("YYYY-MM-DD");
      vm.place = vm.data.jatuhTempoKontrak
    },
    async getMitra() {
      let listMitra = await axios.get(ipBackend + "mitra/list");
      this.dataMitra = listMitra.data.data;

      let manajemen = await axios.get(ipBackend + "masterManajemen/list");
      this.manajemen = manajemen.data.data;

      console.log(manajemen.data.data, "iki");
    },
    async getKoordinator(x) {
      if (x) {
        let koordinator = await axios.get(
          ipBackend + "koordinator/listKoordinatorByMitraId/" + x.id
        );
        this.koordinator = koordinator.data.data;
        this.data.koordinatorId = "";
        this.data.cabangId = "";
        this.data.capemId = "";
      } else if (!x) {
        this.data.koordinatorId = "";
        this.data.cabangId = "";
        this.data.capemId = "";
      }
    },
    async getCabang(x) {
      if (x) {
        let cabang = await axios.get(
          ipBackend + "cabang/listCabangByKoordinatorId/" + x.id
        );
        this.cabang = cabang.data.data;
        this.data.cabangId = "";
        this.data.capemId = "";
      } else if (!x) {
        this.data.cabangId = "";
        this.data.capemId = "";
      }
    },
    async getCapem(x) {
      if (x) {
        let capem = await axios.get(
          ipBackend + "capem/listCapemByCabangId/" + x.id
        );
        this.capem = capem.data.data;
        this.data.capemId = "";
      } else if (!x) {
        this.data.capemId = "";
      }
    },
    simpan() {
      let vm = this;
      vm.button = "Mohon Tunggu";
      vm.busy = true;
      vm.data.tanggalDibuatSurat = moment();
      vm.data.tanggalMutasi = vm.data.tanggalKontrak;
      vm.data.suratPerjanjianKerjaId = vm.dataAsal.suratPerjanjianKerjaId;
      vm.data.jenisMutasi = "Penempatan";
      vm.data.penempatanAwal = vm.dataAsal.penempatan;
      vm.data.penempatanBaru = vm.data.penempatan;
      vm.data.nomorSuratAwal = vm.dataAsal.nomorSuratPerintah;
      vm.data.nomorSuratPerintah = vm.data.nomorSurat;
      vm.data.dataKaryawanId = vm.data.dataKaryawanId.dataKaryawanId;
      if (vm.data.mitraId) {
        vm.data.mitraId = vm.data.mitraId.id;
      }
      if (vm.data.koordinatorId) {
        vm.data.koordinatorId = vm.data.koordinatorId.id;
      }
      if (vm.data.cabangId) {
        vm.data.cabangId = vm.data.cabangId.id;
      }
      if (vm.data.capemId) {
        vm.data.capemId = vm.data.capemId.id;
      }

      console.log(vm.data, "mutasi");
      axios
        .post(ipBackend + "poolKontrak/mutasiPenempatan", vm.data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Konfirmasi Mutasi";
            vm.reset();
            vm.$v.$reset();
            vm.busy = false;
            vm.$emit("alert", {
              variant: "success",
              msg: "BERHASIL MENDAFTARKAN MUTASI PENEMPATAN KARYAWAN",
              showing: true,
            });
            this.$bvModal.hide("modal-input");
          } else {
            vm.button = "Konfirmasi Mutasi";
            vm.busy = false;
            vm.$emit("alert", {
              variant: "danger",
              msg: res.data.message.toUpperCase(),
              showing: true,
            });
          }
        })
        .catch((err) => {
          vm.button = "Konfirmasi Mutasi";
          vm.busy = false;
          vm.$emit("alert", {
            variant: "danger",
            msg: err.message,
            showing: true,
          });
        });
    },
    reset() {
      this.data = {
        jenisMutasi: "",
        nomorSuratAwal: "",
        nomorSuratBaru: "",
        penempatan: "",
        penempatanAwal: "",
        penempatanBaru: "",
        tanggalMutasi: "",
        nomorSurat: "",
        tanggalKontrak: "",
        suratPerjanjianKerjaId: "",
        mitraId: "",
        cabangId: "",
        koordinatorId: "",
        capemId: "",
        masaBerlakuKontrak: "",
      };
    },
  },
  watch: {
    mutate: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.setKaryawan();
      }
    },
  },
};
</script>
