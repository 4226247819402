<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-card header-tag="header" header-bg-variant="dark">
          <template #header>
            <h5 class="mb-0" style="color: #fff">
              <strong>{{ $store.state.table.mutasi }}</strong>
            </h5>
          </template>
          <b-row>
            <b-col cols="12" md="12" lg="12">
              <!-- <b-button variant="primary" v-b-modal.modal-input
                  ><CIcon name="cil-plus" />
                  {{ $store.state.table.tambahModal }}</b-button
                > -->
            </b-col>
          </b-row>
          <b-row>
            <!-- <b-col cols="12" md="12" lg="12">
                <hr />
              </b-col> -->
          </b-row>

          <b-alert dismissible fade :show="showing" :variant="variant">{{
            msg
          }}</b-alert>

          <b-row>
            <b-col md="3">
              <b-form-group
                :label="$store.state.table.perHalaman"
                label-for="per-page-select"
                label-cols-md="6"
                label-align-md="left"
                label-size="md"
                style="background-color: "
              >
                <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="md"
                ></b-form-select>
              </b-form-group>
            </b-col>

            <b-col md="6" offset-md="3">
              <b-form-group
                :label="$store.state.table.cari"
                label-for="filter-input"
                label-cols-md="3"
                label-align-md="right"
                label-size="md"
              >
                <b-input-group size="md">
                  <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    :placeholder="$store.state.table.cariHolder"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                      variant="danger"
                      >{{ $store.state.table.hapusCari }}</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="12" lg="12">
              <b-table
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :filter-included-fields="filterOn"
                stacked="md"
                responsive
                show-empty
                small
                @filtered="onFiltered"
                bordered
                striped
                hover
                :busy="tableBusy"
              >
                <template #cell(tanggalMutasi)="item">
                  {{ moment(item.item.tanggalMutasi).format("LL") }}
                </template>
                <template #cell(actions)="item">
                  <b-button
                    v-if="item.item.statusMutasi == 0"
                    variant="success"
                    class="mr-1"
                    v-c-tooltip.hover.click="'Aktifkan Mutasi'"
                    v-b-modal.modal-aktivasi
                    @click="data = item.item"
                    ><CIcon name="cil-check" /> {{ item.actions }}</b-button
                  >

                  <b-button
                    v-if="item.item.statusMutasi == 0"
                    variant="danger"
                    class="mr-1"
                    v-c-tooltip.hover.click="$store.state.table.tooltipHapus"
                    v-b-modal.modal-delete-mutasi
                    @click="data = item.item"
                    ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
                  >
                </template>
              </b-table>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="3"> Total Data {{ totalRows }} </b-col>
            <b-col md="5" offset-md="4">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <div>
      <b-modal
        id="modal-aktivasi"
        size="lg"
        centered
        scrollable
        title="Konfirmasi Mutasi"
        header-bg-variant="info"
        header-text-variant="light"
      >
        <p>
          Apakah anda yakin akan Melakukan Mutasi {{ data.jenisMutasi }} a.n
          <span class="text-success">{{ data.namaKaryawan }}</span> dari
          <span class="text-success" v-if="data.jenisMutasi == 'Jabatan'">{{
            data.jabatanAwal
          }}</span>
          <span class="text-success" v-if="data.jenisMutasi == 'Penempatan'">{{
            data.penempatanAwal
          }}</span>
          ke
          <span class="text-success" v-if="data.jenisMutasi == 'Jabatan'">{{
            data.jabatanBaru
          }}</span
          ><span class="text-success" v-if="data.jenisMutasi == 'Penempatan'">{{
            data.penempatanBaru
          }}</span>
          ?
        </p>

        <template #modal-footer>
          <b-button
            variant="secondary"
            @click="$bvModal.hide('modal-aktivasi')"
          >
            Batal
          </b-button>
          <b-button variant="primary" @click="aktifkan()" :disabled="busy">
            Yakin
          </b-button>
        </template></b-modal
      >
    </div>
    <div>
      <b-modal
        id="modal-delete-mutasi"
        size="lg"
        centered
        scrollable
        title="Konfirmasi Hapus Mutasi"
        header-bg-variant="info"
        header-text-variant="light"
      >
        <p>
          Apakah anda yakin akan
          <span class="text-danger">Membatalkan</span> Mutasi
          {{ data.jenisMutasi }} a.n
          <span class="text-danger">{{ data.namaKaryawan }}</span> dari
          <span class="text-danger" v-if="data.jenisMutasi == 'Jabatan'">{{
            data.jabatanAwal
          }}</span>
          <span class="text-danger" v-if="data.jenisMutasi == 'Penempatan'">{{
            data.penempatanAwal
          }}</span>
          ke
          <span class="text-danger" v-if="data.jenisMutasi == 'Jabatan'">{{
            data.jabatanBaru
          }}</span
          ><span class="text-danger" v-if="data.jenisMutasi == 'Penempatan'">{{
            data.penempatanBaru
          }}</span>
          ?
        </p>

        <template #modal-footer>
          <b-button
            variant="secondary"
            @click="$bvModal.hide('modal-delete-mutasi')"
          >
            Batal
          </b-button>
          <b-button variant="primary" @click="hapusMutasi()" :disabled="busy">
            Yakin
          </b-button>
        </template></b-modal
      >
    </div>
  </b-container>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend.js";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
// import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
// import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import "moment/locale/id";
// import mutasiJabatan from "./mutasiJabatan.vue";
// import mutasiLokasi from "./mutasiLokasi.vue";
// import perpanjangPerjanjian from "./perpanjangPerjanjian.vue";

export default {
  props: ["news"],
  components: {
    // Multiselect,
    // DatePicker,
    // mutasiJabatan,
    // mutasiLokasi,
    // perpanjangPerjanjian,
  },
  name: "asuransi",
  data() {
    return {
      moment,
      showing: false,
      variant: "success",
      mutate: false,
      busy: false,
      msg: "",
      jenis: [
        { value: "", text: "Pilih Jenis Mutasi" },
        { value: "Jabatan", text: "Jabatan" },
        { value: "Penempatan", text: "Penempatan" },
        { value: "Perpanjang", text: "Perpanjang Kontrak" },
      ],
      data: "",
      jenisMutasi: "",
      dataAsal: {},
      karyawan: [],
      karyawanId: "",
      dataMitra: [],
      koordinator1: [],
      koordinator: [],
      cabang: [],
      capem: [],
      noSuratPerjanjian: "",
      noSuratPerintah: "",
      items: [
        {
          no: "-",
          nmAsuransi: "-",
        },
      ],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "namaKaryawan",
          label: "Nama Karyawan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "jenisMutasi",
          label: "Mutasi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "tanggalMutasi",
          label: "Tanggal Mutasi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "penempatanAwal",
          label: "Lokasi Lama",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "penempatanBaru",
          label: "Lokasi Baru",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "jabatanAwal",
          label: "Posisi Lama",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "jabatanBaru",
          label: "Posisi Baru",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    formString() {
      return JSON.stringify(this.data, null, 4);
    },
    isValid() {
      return !this.$v.data.$invalid;
    },
    isDirty() {
      return this.$v.data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    data: {
      dataKaryawanId: {
        required,
      },
      tanggalKontrak: {
        required,
      },
      masaBerlakuKontrak: {
        required,
      },
      mitraId: {
        required,
      },
      koordinatorId: {
        required,
      },
      nomorSurat: {
        required,
      },
    },
  },
  mounted() {
    // Set the initial number of items
    this.getDatas();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getKaryawan() {
      let vm = this;
      if (vm.jenisMutasi == "Jabatan") {
        let listKaryawan = await axios.get(
          ipBackend + "suratPerjanjianKerja/listPerjanjianKerjaAktif"
        );

        let penampung = listKaryawan.data.data;
        vm.karyawan = penampung.map((item) => {
          item.dataKaryawan =
            item.namaKaryawan +
            " - " +
            item.namaDivisi +
            " - " +
            item.namaPosisi;
          return item;
        });
      } else if (vm.jenisMutasi == "Penempatan") {
        let listKaryawan = await axios.get(
          ipBackend + "poolKontrak/listPoolKontrakAktif"
        );

        let penampung = listKaryawan.data.data;
        vm.karyawan = penampung.map((item) => {
          item.dataKaryawan =
            item.namaKaryawan +
            " - " +
            item.namaDivisi +
            " - " +
            item.namaPosisi;
          return item;
        });
      }
    },
    async getDatas() {
      let vm = this;
      vm.tableBusy = true;
      let listMutasi = await axios.get(ipBackend + "mutasi/list");
      // console.log(listMutasi.data.data);
      vm.items = listMutasi.data.data;
      for (let i = 0; i < vm.items.length; i++) {
        let x = vm.items[i];
        x.no = i + 1;
      }
      vm.totalRows = vm.items.length;

      let listMitra = await axios.get(ipBackend + "mitra/list");
      vm.dataMitra = listMitra.data.data;

      let listKaryawan = await axios.get(
        ipBackend + "dataKaryawan/listKaryawan"
      );

      let penampung = listKaryawan.data.data;
      vm.karyawan = penampung.map((item) => {
        item.dataKaryawan =
          item.namaKaryawan + " - " + item.namaDivisi + " - " + item.namaPosisi;
        return item;
      });

      vm.tableBusy = false;
    },
    aktifkan() {
      let vm = this;
      let links = "";
      if (vm.data.jenisMutasi == "Jabatan") {
        links = "suratPerjanjianKerja/aktifkanMutasi";
      } else if (vm.data.jenisMutasi == "Penempatan") {
        links = "poolKontrak/aktifkanMutasi";
      }
      // console.log(vm.data, "aktif");
      axios
        .post(ipBackend + links, vm.data)
        .then((res) => {
          if (res.data.status == 200) {
            if (res.data.message == "sukses") {
              vm.$bvModal.hide("modal-aktivasi");
              vm.busy = false;
              vm.getDatas();
              vm.$emit("change");
              vm.variant = "success";
              vm.msg = "BERHASIL MENGAKTIFKAN MUTASI";
              vm.showing = true;
              setTimeout(() => {
                vm.showing = false;
              }, 4000);
            } else {
              vm.button = "Simpan";
              vm.busy = false;
              vm.variant = "danger";
              vm.msg = _.toUpper(res.data.message);
              vm.showing = true;
              setTimeout(() => {
                vm.showing = false;
              }, 4000);
            }
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.variant = "danger";
          vm.msg = "TERJADI KESALAHAN PADA SERVER";
          vm.showing = true;
          setTimeout(() => {
            vm.showing = false;
          }, 4000);
        });
    },
    hapusMutasi() {
      let vm = this;
      let links = "";
      if (vm.data.jenisMutasi == "Jabatan") {
        links = "suratPerjanjianKerja/deleteMutasi";
      } else if (vm.data.jenisMutasi == "Penempatan") {
        links = "poolKontrak/deleteMutasiPenempatan";
      }
      // console.log(vm.data);
      vm.busy = true;
      axios
        .post(ipBackend + links, vm.data)
        .then((res) => {
          if (res.data.status == 200) {
            if (res.data.message == "sukses") {
              vm.$bvModal.hide("modal-delete-mutasi");
              vm.busy = false;
              vm.getDatas();
              vm.$emit("change");
              vm.variant = "success";
              vm.msg = "BERHASIL MEMBATALKAN MUTASI";
              vm.showing = true;
              setTimeout(() => {
                vm.showing = false;
              }, 4000);
            } else {
              vm.button = "Simpan";
              vm.busy = false;
              vm.variant = "danger";
              vm.msg = _.toUpper(res.data.message);
              vm.showing = true;
              setTimeout(() => {
                vm.showing = false;
              }, 4000);
            }
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.variant = "danger";
          vm.msg = "TERJADI KESALAHAN PADA SERVER";
          vm.showing = true;
          setTimeout(() => {
            vm.showing = false;
          }, 4000);
        });
    },
    alert(event) {
      let vm = this;
      // console.log(event);
      // vm.showing = event.showing;
      // vm.variant = event.variant;
      // vm.msg = event.msg;
      vm.$store.commit("set_alert", event);
      vm.getDatas();
      // setTimeout(() => {
      //   vm.showing = false;
      // }, 4000);
    },
    checkIfValid(fieldName) {
      const field = this.$v.data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    async itikiwir(x) {
      let vm = this;
      vm.noSuratPerjanjian = x.nomorSurat;
      vm.noSuratPerintah = x.nomorSurat;
      vm.dataAsal = x;
      console.log(x);
      vm.mutate = !vm.mutate;
    },
    async getKoordinator(x) {
      let koordinator = await axios.get(
        ipBackend + "koordinator/listKoordinatorByMitraId/" + x.id
      );
      //   console.log(koordinator.data.data, "ini koordinator");
      this.koordinator = koordinator.data.data;
    },
    async getCabang(x) {
      let cabang = await axios.get(
        ipBackend + "cabang/listCabangByKoordinatorId/" + x.id
      );
      //   console.log(cabang.data.data, "ini cabang");
      this.cabang = cabang.data.data;
    },
    async getCapem(x) {
      let capem = await axios.get(
        ipBackend + "capem/listCapemByCabangId/" + x.id
      );
      //   console.log(cabang.data.data, "ini cabang");
      this.capem = capem.data.data;
    },
  },
  watch: {
    news: function (oldVal, newVal) {
      if (oldVal != newVal) {
        this.getDatas();
      }
    },
  },
};
</script>
